import React from 'react';
import { Routes, Route, Link } from 'react-router-dom';
import ExerciseList from './components/ExerciseList';
import RecordExercise from './components/RecordExercise';
import ExerciseTypeManager from './components/ExerciseTypeManager';
import { SupabaseProvider } from './contexts/SupabaseContext';
import './App.css';

function App() {
  return (
    <SupabaseProvider>
      <div className="container">
        <h1>Gym Exercise Tracker</h1>
        <nav>
          <Link to="/" className="btn">Exercise List</Link>
          <Link to="/record" className="btn">Record Exercise</Link>
          <Link to="/manage-exercises" className="btn">Manage Exercises</Link>
        </nav>
        <Routes>
          <Route path="/" element={<ExerciseList />} />
          <Route path="/record" element={<RecordExercise />} />
          <Route path="/manage-exercises" element={<ExerciseTypeManager />} />
        </Routes>
      </div>
    </SupabaseProvider>
  );
}

export default App;
