import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useSupabase } from '../contexts/SupabaseContext';

function RecordExercise() {
  const formRef = useRef(null);
  const [exerciseType, setExerciseType] = useState('weightlifting');
  const [exerciseList, setExerciseList] = useState([]);
  const [selectedExercise, setSelectedExercise] = useState('');
  const [username, setUsername] = useState('Ruben');
  const [reps, setReps] = useState('');
  const [weight, setWeight] = useState('');
  const [duration, setDuration] = useState('');
  const [watts, setWatts] = useState('');
  const [heartRate, setHeartRate] = useState('');
  const [lastSelectedExercise, setLastSelectedExercise] = useState('');
  const [previousRecords, setPreviousRecords] = useState([]);
  const [comment, setComment] = useState('');
  const supabase = useSupabase();

  const flashScreen = useCallback(() => {
    if (formRef.current) {
      formRef.current.style.transition = 'background-color 0.3s';
      formRef.current.style.backgroundColor = '#4CAF50';
      setTimeout(() => {
        formRef.current.style.backgroundColor = '';
      }, 300);
    }
  }, []);

  const fetchExerciseList = useCallback(async () => {
    const { data, error } = await supabase
      .from('exercise_list')
      .select('*')
      .order('name', { ascending: true });

    if (error) {
      console.error('Error fetching exercise list:', error);
    } else {
      setExerciseList(data);
    }
  }, [supabase]);

  useEffect(() => {
    fetchExerciseList();
  }, [fetchExerciseList]);

  const fetchPreviousRecords = useCallback(async () => {
    if (!username) return;

    let query = supabase
      .from('exercises')
      .select(`
        *,
        exercise_list!inner(id, name)
      `)
      .eq('type', exerciseType)
      .eq('username', username)
      .order('date', { ascending: false })
      .limit(4);

    if (exerciseType === 'weightlifting' && selectedExercise) {
      query = query.eq('exercise_list.name', selectedExercise);
    } else if (exerciseType === 'cardio') {
      query = query.eq('exercise_list.name', 'Cardio');
    }

    const { data, error } = await query;

    if (error) {
      console.error('Error fetching previous records:', error);
    } else {
      setPreviousRecords(data);
    }
  }, [supabase, selectedExercise, username, exerciseType]);

  useEffect(() => {
    if (exerciseType === 'cardio' || (exerciseType === 'weightlifting' && selectedExercise)) {
      fetchPreviousRecords();
    } else {
      setPreviousRecords([]);
    }
  }, [fetchPreviousRecords, exerciseType, selectedExercise]);

  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();
    if (exerciseType === 'weightlifting' && !selectedExercise) {
      alert('Please select an exercise before recording.');
      return;
    }

    const currentDateTime = new Date().toISOString();
    
    if (exerciseType === 'weightlifting') {
      const { data, error } = await supabase
        .from('exercises')
        .select('*, exercise_list!inner(id, name)')
        .eq('type', 'weightlifting')
        .eq('exercise_list.name', selectedExercise)
        .eq('username', username)
        .eq('date', currentDateTime.split('T')[0])
        .single();

      if (error && error.code !== 'PGRST116') {
        console.error('Error fetching existing exercise:', error);
        return;
      }

      const newSet = { reps: parseInt(reps), weight: parseFloat(weight) };

      if (data) {
        // Update existing exercise
        const updatedSets = [...data.sets, newSet];
        const { error: updateError } = await supabase
          .from('exercises')
          .update({ sets: updatedSets, comment: comment || null })
          .eq('id', data.id);

        if (updateError) {
          console.error('Error updating exercise:', updateError);
        } else {
          flashScreen();
        }
      } else {
        // Insert new exercise
        // Get the exercise_list id for the selected exercise
        const { data: exerciseListData, error: exerciseListError } = await supabase
          .from('exercise_list')
          .select('id')
          .eq('name', selectedExercise)
          .single();

        if (exerciseListError) {
          console.error('Error fetching exercise_list id:', exerciseListError);
          return;
        }

        const { error: insertError } = await supabase
          .from('exercises')
          .insert({
            type: 'weightlifting',
            name_id: exerciseListData.id,
            username,
            date: currentDateTime,
            sets: [newSet],
            comment: comment || null,
          });

        if (insertError) {
          console.error('Error inserting new exercise:', insertError);
        } else {
          flashScreen();
        }
      }
    } else {
      // Handle cardio exercise
      // First, check if 'Cardio' exists in exercise_list
      // Get the exercise_list id for 'Cardio'
      let cardioId;
      const { data: cardioData, error: cardioError } = await supabase
        .from('exercise_list')
        .select('id')
        .eq('name', 'Cardio')
        .single();

      if (cardioError) {
        if (cardioError.code === 'PGRST116') {
          // 'Cardio' doesn't exist, so we need to insert it
          const { data: insertedCardio, error: insertCardioError } = await supabase
            .from('exercise_list')
            .insert({ name: 'Cardio' })
            .select('id')
            .single();

          if (insertCardioError) {
            console.error('Error inserting Cardio exercise:', insertCardioError);
            return;
          }
          cardioId = insertedCardio.id;
        } else {
          console.error('Error fetching Cardio exercise_list id:', cardioError);
          return;
        }
      } else {
        cardioId = cardioData.id;
      }

      const { error } = await supabase
        .from('exercises')
        .insert({
          type: 'cardio',
          name_id: cardioId,
          username,
          date: currentDateTime,
          duration: parseInt(duration),
          watts: parseInt(watts),
          heart_rate: parseInt(heartRate),
          comment: comment || null,
        });

      if (error) {
        console.error('Error recording cardio exercise:', error);
      } else {
        flashScreen();
      }
    }

    setLastSelectedExercise(selectedExercise);
    resetForm();
    // Alternate username
    setUsername(username === 'Ruben' ? 'Milena' : 'Ruben');
  }, [exerciseType, selectedExercise, username, reps, weight, duration, watts, heartRate, comment, supabase, flashScreen]);

  function resetForm() {
    setReps('');
    setWeight('');
    setDuration('');
    setWatts('');
    setHeartRate('');
    setComment('');
  }

  return (
    <div>
      <h2>Record Exercise</h2>
      <form onSubmit={handleSubmit} ref={formRef}>
        <select value={exerciseType} onChange={(e) => setExerciseType(e.target.value)}>
          <option value="weightlifting">Weight Lifting</option>
          <option value="cardio">Cardio</option>
        </select>

        {exerciseType === 'weightlifting' && (
          <select 
            value={selectedExercise || lastSelectedExercise} 
            onChange={(e) => setSelectedExercise(e.target.value)}
          >
            <option value="">Select Exercise</option>
            {exerciseList.map((exercise) => (
              <option key={exercise.id} value={exercise.name}>{exercise.name}</option>
            ))}
          </select>
        )}

        <select value={username} onChange={(e) => setUsername(e.target.value)}>
          <option value="Ruben">Ruben</option>
          <option value="Milena">Milena</option>
        </select>

        {exerciseType === 'weightlifting' && (
          <div className="weightlifting-inputs">
            <input
              type="number"
              placeholder="Reps"
              value={reps}
              onChange={(e) => setReps(e.target.value)}
              required
            />
            <span className="separator">x</span>
            <input
              type="number"
              placeholder="Weight (kg)"
              value={weight}
              onChange={(e) => setWeight(e.target.value)}
              required
            />
          </div>
        )}

        {exerciseType === 'cardio' && (
          <>
            <input
              type="number"
              placeholder="Duration (minutes)"
              value={duration}
              onChange={(e) => setDuration(e.target.value)}
              required
            />
            <input
              type="number"
              placeholder="Average Watts"
              value={watts}
              onChange={(e) => setWatts(e.target.value)}
              required
            />
            <input
              type="number"
              placeholder="Heart Rate (bpm)"
              value={heartRate}
              onChange={(e) => setHeartRate(e.target.value)}
              required
            />
          </>
        )}

        <input
          type="text"
          placeholder="Comment (optional)"
          value={comment}
          onChange={(e) => setComment(e.target.value)}
        />

        <button type="submit" className="btn">Record Exercise</button>
      </form>

      {previousRecords.length > 0 && (exerciseType === 'cardio' || (exerciseType === 'weightlifting' && selectedExercise)) && (
        <div className="previous-records">
          <h3>Previous Sets</h3>
          <table>
            <thead>
              <tr>
                <th>Date</th>
                {exerciseType === 'weightlifting' ? (
                  <>
                    <th>Reps</th>
                    <th>Weight (kg)</th>
                  </>
                ) : (
                  <>
                    <th>Duration (min)</th>
                    <th>Watts</th>
                    <th>BPM</th>
                  </>
                )}
                <th>Comment</th>
              </tr>
            </thead>
            <tbody>
              {previousRecords.flatMap((record, recordIndex) => 
                exerciseType === 'weightlifting' 
                  ? (record.sets || []).map((set, setIndex) => (
                      <tr key={`${recordIndex}-${setIndex}`}>
                        <td>{new Date(record.date).toLocaleString('en-GB', { dateStyle: 'short', timeStyle: 'short' })}</td>
                        <td>{set.reps}</td>
                        <td>{set.weight}</td>
                        <td>{record.comment}</td>
                      </tr>
                    ))
                  : [
                      <tr key={recordIndex}>
                        <td>{new Date(record.date).toLocaleString('en-GB', { dateStyle: 'short', timeStyle: 'short' })}</td>
                        <td>{record.duration}</td>
                        <td>{record.watts}</td>
                        <td>{record.heart_rate}</td>
                        <td>{record.comment}</td>
                      </tr>
                    ]
              ).slice(0, 10)}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

export default RecordExercise;
