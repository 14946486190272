import React, { useState, useEffect, useCallback } from 'react';
import { useSupabase } from '../contexts/SupabaseContext';

function ExerciseList() {
  const calculateRestTime = (currentDate, previousDate) => {
    if (!previousDate || !currentDate) return '';
    const currentTime = new Date(currentDate).getTime();
    const previousTime = new Date(previousDate).getTime();
    const restTimeInSeconds = Math.round((currentTime - previousTime) / 1000);
    return restTimeInSeconds;
  };
  const [exercises, setExercises] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date().toISOString().split('T')[0]);
  const [exerciseDates, setExerciseDates] = useState([]);
  const supabase = useSupabase();

  const groupExercises = useCallback((data) => {
    console.log('Raw data:', data);
    const grouped = {};
    data.forEach(exercise => {
      const exerciseName = exercise.exercise_list.name;
      const key = `${exercise.type}-${exerciseName}`;
      if (!grouped[key]) {
        grouped[key] = { ...exercise, name: exerciseName, users: {} };
      }
      if (exercise.type === 'weightlifting') {
        if (!grouped[key].users[exercise.username]) {
          grouped[key].users[exercise.username] = [];
        }
        grouped[key].users[exercise.username].push({
          id: exercise.id,
          sets: exercise.sets.map(set => ({ ...set, date: exercise.date })),
          comment: exercise.comment,
          date: exercise.date
        });
      } else {
        if (!grouped[key].users[exercise.username]) {
          grouped[key].users[exercise.username] = [];
        }
        grouped[key].users[exercise.username].push({
          id: exercise.id,
          duration: exercise.duration,
          watts: exercise.watts,
          heart_rate: exercise.heart_rate,
          comment: exercise.comment,
          date: exercise.date
        });
      }
    });

    // Sort sets by date in descending order
    Object.values(grouped).forEach(exercise => {
      if (exercise.type === 'weightlifting') {
        Object.values(exercise.users).forEach(userEntries => {
          userEntries.sort((a, b) => new Date(b.date) - new Date(a.date));
          userEntries.forEach(entry => {
            entry.sets.sort((a, b) => new Date(b.date) - new Date(a.date));
          });
        });
      }
    });

    console.log('Grouped exercises:', grouped);
    return Object.values(grouped);
  }, []);

  const fetchExercises = useCallback(async () => {
    const { data, error } = await supabase
      .from('exercises')
      .select(`
        *,
        exercise_list!exercises_name_id_fkey (
          id,
          name
        )
      `)
      .gte('date', `${selectedDate}T00:00:00`)
      .lt('date', `${selectedDate}T23:59:59`)
      .order('type', { ascending: true })
      .order('exercise_list(name)', { ascending: true })
      .order('username', { ascending: true })
      .order('date', { ascending: true });

    if (error) {
      console.error('Error fetching exercises:', error);
    } else {
      const groupedExercises = groupExercises(data);
      setExercises(groupedExercises);
    }
  }, [supabase, selectedDate, groupExercises]);

  const fetchExerciseDates = useCallback(async () => {
    const { data, error } = await supabase
      .from('exercises')
      .select('date')
      .order('date', { ascending: false });

    if (error) {
      console.error('Error fetching exercise dates:', error);
    } else {
      const uniqueDates = [...new Set(data.map(item => item.date.split('T')[0]))];
      setExerciseDates(uniqueDates);
    }
  }, [supabase]);

  useEffect(() => {
    fetchExerciseDates();
    fetchExercises();
  }, [fetchExerciseDates, fetchExercises]);

  function changeDate(direction) {
    const currentIndex = exerciseDates.indexOf(selectedDate);

    if (direction === 'next') {
      if (currentIndex > 0) {
        setSelectedDate(exerciseDates[currentIndex - 1]);
      } else {
        // If there are no next dates, go to today
        setSelectedDate(new Date().toISOString().split('T')[0]);
      }
    } else {
      if (currentIndex < exerciseDates.length - 1) {
        setSelectedDate(exerciseDates[currentIndex + 1]);
      }
      // If there are no previous dates, stay on the current date
    }
  }

  async function deleteExercise(exerciseId, exerciseName) {
    const isConfirmed = window.confirm(`Are you sure you want to delete this ${exerciseName} exercise?`);
    if (isConfirmed) {
      const { error } = await supabase
        .from('exercises')
        .delete()
        .eq('id', exerciseId);

      if (error) {
        console.error('Error deleting exercise:', error);
      } else {
        fetchExercises();
      }
    }
  }

  return (
    <div>
      <h2>Exercise List</h2>
      <div>
        <button onClick={() => changeDate('prev')} className="btn">&lt;</button>
        <span>{new Date(selectedDate).toLocaleDateString()}</span>
        <button onClick={() => changeDate('next')} className="btn">&gt;</button>
      </div>
      <div className="exercise-list">
        {exercises.map((exercise) => (
          <div key={exercise.id} className="exercise-item">
            <h3>{exercise.name} ({exercise.type})</h3>
            {Object.entries(exercise.users).map(([username, data]) => (
              <div key={username}>
                <h4>{username}</h4>
                <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                  <thead>
                    <tr>
                      {exercise.type === 'weightlifting' ? (
                        <>
                          <th style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'center', width: '12%' }}>Reps</th>
                          <th style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'center', width: '12%' }}>Weight (kg)</th>
                          <th style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'center', width: '12%' }}>Rest (s)</th>
                        </>
                      ) : (
                        <>
                          <th style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'center', width: '12%' }}>Duration (min)</th>
                          <th style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'center', width: '12%' }}>Watts</th>
                          <th style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'center', width: '12%' }}>BPM</th>
                        </>
                      )}
                      <th style={{ border: '1px solid #ddd', padding: '8px', width: '40%' }}>Comment</th>
                      <th style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'center', width: '12%' }}></th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((entry, index) => (
                      <React.Fragment key={entry.id}>
                        {exercise.type === 'weightlifting' ? (
                          entry.sets.map((set, setIndex) => (
                            <tr key={`${entry.id}-${setIndex}`}>
                              <td style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'center', width: '12%' }}>{set.reps}</td>
                              <td style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'center', width: '12%' }}>{set.weight}</td>
                              <td style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'center', width: '12%' }}>
                                {setIndex < entry.sets.length - 1 ? calculateRestTime(entry.sets[setIndex].date, entry.sets[setIndex + 1].date) : 
                                 (index < data.length - 1 ? calculateRestTime(entry.sets[setIndex].date, data[index + 1].sets[data[index + 1].sets.length - 1].date) : '')}
                              </td>
                              <td style={{ border: '1px solid #ddd', padding: '8px', width: '40%' }}>{setIndex === 0 ? entry.comment : ''}</td>
                              <td style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'center', width: '12%' }}>
                                {setIndex === 0 && <button onClick={() => deleteExercise(entry.id, exercise.name)} className="btn btn-small">X</button>}
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'center', width: '12%' }}>{entry.duration}</td>
                            <td style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'center', width: '12%' }}>{entry.watts}</td>
                            <td style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'center', width: '12%' }}>{entry.heart_rate}</td>
                            <td style={{ border: '1px solid #ddd', padding: '8px', width: '40%' }}>{entry.comment}</td>
                            <td style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'center', width: '12%' }}>
                              <button onClick={() => deleteExercise(entry.id, exercise.name)} className="btn btn-small">X</button>
                            </td>
                          </tr>
                        )}
                      </React.Fragment>
                    ))}
                  </tbody>
                </table>
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
}

export default ExerciseList;
